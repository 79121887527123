<template>
  <div>
    <b-card>
      <b-row>
        <b-col lg="6">
          <b-overlay
            :show="loadingOverlay"
            rounded="sm"
          >
            <validation-observer ref="simpleRules">
              <b-form @submit.prevent="submitForm">
                <b-row>
                  <b-col
                    lg="12"
                    class="mb-2"
                  >
                    <h5>{{ $t('futures.transferencia.transferirSaldo') }} </h5>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6">
                    <b-form-group>
                      <label for="trocar-moeda">{{ $t('futures.transferencia.transferirDaCarteiraSpot') }}</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Transferir"
                        rules="required"
                      >
                        <b-form-select
                          v-model="data.coin"
                          :options="optionsFrom"
                          input-id="trocar-moeda"
                          :state="errors.length > 0 ? false:null"
                          @change="alteraMoedaFrom"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <div
                        v-if="data.coin!=null"
                        class="font-weight-bolder mt-1"
                      >
                        {{ $t('futures.transferencia.disponivelParaTransferencia') }}
                        <div class="text-success">
                          {{ saldo }} {{ data.coin }}
                        </div>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6">
                    <b-form-group>
                      <label for="valor">
                        {{ $t('futures.transferencia.valorAReceberDe') }}
                        <span class="font-weight-bolder">{{ data.coin }}</span>
                        {{ $t('futures.transferencia.naCarteiraFutures') }}
                      </label>
                      <validation-provider
                        #default="{ errors }"
                        name="Valor a transferir"
                        :rules="{ required: true, regex: /^[+]?\d+(\.\d+)?$/, min_value: moedaFromMinValue }"
                      >
                        <b-form-input
                          id="valor"
                          v-model="data.quantia"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-button
                      variant="primary"
                      type="submit"
                    >
                      {{ $t('futures.transferencia.transferirAgoraBtn') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-overlay>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>

import {
  BRow, BButton, BOverlay, BCol, BFormGroup, BCard, BFormSelect, BForm, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, minValue } from '@validations'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import Ripple from 'vue-ripple-directive'
import transferStoreModule from './transferStoreModule'

const TRANSFER_FUT_STORE_MODULE_NAME = 'transfer-fut'

export default {

  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BOverlay,
    BCard,
    BFormSelect,
    BForm,
    BFormInput,
  },

  directives: {
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      loadingOverlay: false,
      dataResponse: {
        status: null,
      },
      data: {
        coin: null,
        quantia: '',
      },
      saldo: '',
      required,
      minValue,
    }
  },

  computed: {
    optionsFrom() {
      return [
        { value: null, text: this.$i18n.t('futures.transferencia.escolhaMoeda') },
        { value: 'BNB', text: 'BNB' },
        { value: 'USDT', text: 'USDT' },
      ]
    },
    moedaFromMinValue() {
      let min = 10
      if (this.data.coin === 'BNB') {
        min = 0.001
      }
      return min
    },

  },

  created() {
    if (!store.hasModule(TRANSFER_FUT_STORE_MODULE_NAME)) store.registerModule(TRANSFER_FUT_STORE_MODULE_NAME, transferStoreModule)
  },

  beforeDestroy() {
    if (store.hasModule(TRANSFER_FUT_STORE_MODULE_NAME)) store.unregisterModule(TRANSFER_FUT_STORE_MODULE_NAME)
  },

  methods: {

    novaOperacao() {
      this.dataResponse.status = null
    },

    alteraMoedaFrom() {
      this.data.quantia = 0
      this.getSaldo(this.data.coin)
    },

    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loadingOverlay = true
          this.$store.dispatch('transfer-fut/transferFutures', this.data)
            .then(res => {
              this.$swal({
                icon: 'success',
                title: 'Transferência efetuada',
                html: `Você acabou de transferir <strong>${res.data.amount} ${res.data.asset}</strong> para carteira Futures</strong>`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
                buttonsStyling: false,
              })
              this.data.quantia = ''
              this.data.coin = null
              this.dataResponse = res.data
              this.$refs.simpleRules.reset()
            })
            .catch(error => {
              this.$swal({
                title: 'Error!',
                text: error.data,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .finally(() => {
              this.loadingOverlay = false
            })
        }
      })
    },

    getSaldo(moeda) {
      this.loadingOverlay = true
      this.$store.dispatch('transfer-fut/getSaldo', moeda)
        .then(res => { this.saldo = res.data })
        .finally(() => {
          this.loadingOverlay = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
