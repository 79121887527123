import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getSaldo(ctx, symbol) {
      return new Promise((resolve, reject) => {
        axios.get(`/usuario-info/saldo/${symbol}`)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    transferFutures(ctx, playload) {
      return new Promise((resolve, reject) => {
        axios.post('/cambio/futures/transfer', playload)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

  },
}
